<script setup lang="ts">
export interface Props {
}

const open = defineModel<boolean>('open')
</script>

<template>
   <div
      :class="[open ? 'grid-rows-[1fr]' : 'grid-rows-[0fr]']"
      class="ease grid overflow-hidden transition-all duration-500"
   >
      <div class="min-h-0 w-full">
         <slot />
      </div>
   </div>
</template>
